export const locales = ["en-US", "de", "da", "hu", "pl"];

export const languages: Record<string, Common.Language> = {
    da: { countryName: "Danish", flag: "/img/languages/denmark.svg" },
    "en-US": { countryName: "English", flag: "/img/languages/england.svg" },
    de: { countryName: "German", flag: "/img/languages/germany.svg" },
    hu: { countryName: "Hungarian", flag: "/img/languages/hungary.svg" },
    pl: { countryName: "Polish", flag: "/img/languages/poland.svg" },
};

export const defaultLocale = "en-US";
