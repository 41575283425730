import { createClient } from "contentful-management";
import { managementConfig } from "../../config/contentful";

const contentfulClient = createClient(
    {
        accessToken: managementConfig.apiKey || "",
    },
    {
        type: "plain",
        defaults: {
            spaceId: managementConfig.spaceId,
            environmentId: managementConfig.environmentId,
        },
    }
);

export default contentfulClient;
