export const wait = (ms: number) => new Promise(r => setTimeout(r, ms));

export const retryOperation = (
    operation: () => Promise<unknown>,
    delay: number,
    retries: number
): Promise<unknown> => {
    return new Promise((resolve, reject) => {
        return operation()
            .then(resolve)
            .catch(reason => {
                if (retries > 0) {
                    return wait(delay)
                        .then(retryOperation.bind(null, operation, delay, retries - 1))
                        .then(resolve)
                        .catch(reject);
                }
                return reject(reason);
            });
    });
};

export const matchArray = (arr: string[], newArr: string[]) => {
    if (!arr.length || !newArr.length) {
        return [...arr, ...newArr];
    }

    return arr.filter(item => newArr.includes(item));
};

export const flattenArray = <T = unknown>(arr: T[][]): T[] => {
    return Array.prototype.concat.apply([], arr);
};

export const sysToFacet = (items: any[], keySelector: string, valueSelector?: string) => {
    return items.reduce((map, item) => {
        map[item[keySelector]] = valueSelector ? item[valueSelector] : item.sys.id;
        return map;
    }, {} as Record<string, string>);
};

export const arrToMap = (items: any[], keySelector: string): Record<string, any> => {
    return items.reduce((map, item) => {
        map[item[keySelector]] = item;
        return map;
    }, {} as Record<string, any>);
};

export const removeDuplicatesBySysId = <T extends Contentful.Item>(items: T[]): T[] => {
    const obj = items.reduce((map, item) => {
        map[item.sys.id] = item;
        return map;
    }, {} as Record<string, T>);

    return Object.keys(obj).map(id => obj[id]);
};
