import { GetServerSidePropsContext } from "next";

export const fetchData = async <T>(
    path: string,
    ctx?: GetServerSidePropsContext
): Promise<T | null> => {
    try {
        const ssr = typeof window === "undefined";
        const baseUrl = ssr ? _appBaseUrl() : "";
        const options = ctx ? { headers: { cookie: ctx.req.headers.cookie || "" } } : {};
        const res = await fetch(`${baseUrl}/api/auth/${path}`, options);
        if (!res.ok) return null;
        const data = await res.json();
        return Object.keys(data).length > 0 ? data : null;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const _appBaseUrl = () => {
    const baseUrl = process.env.APP_URL;

    if (!baseUrl) {
        throw new Error("APP_URL environment variable not set");
    }

    return baseUrl;
};
