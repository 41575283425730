import React, { createContext, useContext } from "react";

interface NewsContextData {
    facets: Filters.Facets;
    selectedFacets: Record<string, Record<string, string>>;
    categories: Record<string, string>;
    handleSelectFacet: (facetKey: string, value: string) => void;
    handleDeleteFacet: (facetKey: string) => void;
    handleReset: () => void;
    alert: News.Emergency | null;
    showActiveAlert: () => void;
}

export const NewsContext = createContext<NewsContextData>({} as NewsContextData);

export const useNews = () => useContext(NewsContext);
