import { createContext, useContext } from "react";

interface OrganizationData {
    index: Organization.Index;
    department: Organization.Department | null;
    departments: Organization.Department[];
    positions: Organization.Position[];
    employee: Organization.Employee | null;
    employees: Organization.Employee[];
    setDepartmentById: (id?: string | null) => void;
    setEmployeeById: (id?: string | null) => void;
    isChart: boolean;
    setIsChart: (isChart: boolean) => void;
}

export const OrganizationContext = createContext<OrganizationData>({} as OrganizationData);

export const useOrganization = () => useContext(OrganizationContext);
