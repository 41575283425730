import { gql } from "@apollo/client";
import { FRAGMENT_ASSET, FRAGMENT_SYS } from "../../fragment";
import {
    FRAGMENT_IMAGE,
    FRAGMENT_IMAGE_SLIDER,
    FRAGMENT_NEWS_MAIN_TAG,
    FRAGMENT_NEWS_SUB_TAG,
} from "../fragment";

export const FRAGMENT_NEWS_INTERNAL_FEED = gql`
    fragment NewsInternalFeedFields on NewsInternalFeed {
        sys {
            ...SysFields
        }
        title
        teaserText
        image {
            ...ImageFields
        }
        author {
            name
        }
    }
    ${FRAGMENT_SYS}
    ${FRAGMENT_IMAGE}
`;

export const FRAGMENT_NEWS_INTERNAL_FEED_DETAILS = gql`
    fragment NewsInternalFeedDetailsFields on NewsInternalFeed {
        imageSlider {
            ...ImageSliderFields
        }
        video
        rating
        text {
            json
            links {
                assets {
                    block {
                        ...AssetFields
                    }
                }
            }
        }
        author {
            name
            avatar {
                ...AssetFields
            }
        }
        mainTagsCollection {
            items {
                ...NewsMainTagFields
            }
        }
        subTagsCollection {
            items {
                ...NewsSubTagFields
            }
        }
    }
    ${FRAGMENT_SYS}
    ${FRAGMENT_ASSET}
    ${FRAGMENT_NEWS_MAIN_TAG}
    ${FRAGMENT_NEWS_SUB_TAG}
    ${FRAGMENT_IMAGE_SLIDER}
`;

export const FRAGMENT_NEWS_EXTERNAL_FEED = gql`
    fragment NewsExternalFeedFields on NewsExternalFeed {
        sys {
            ...SysFields
        }
        link
        title
        mainImage
        author {
            name
        }
    }
    ${FRAGMENT_SYS}
`;
