import { gql } from "@apollo/client";
import {
    FRAGMENT_NEWS_EXTERNAL_FEED,
    FRAGMENT_NEWS_INTERNAL_FEED,
    FRAGMENT_NEWS_INTERNAL_FEED_DETAILS,
} from "./fragment";
import { FRAGMENT_SYS } from "../../fragment";

export const NEWS_FEED = gql`
    query GetNewsFeed($cities: [String], $locale: String, $preview: Boolean) {
        newsFeedCollection(
            limit: 20
            locale: $locale
            preview: $preview
            order: [sys_firstPublishedAt_DESC]
            where: { locations: { city_in: $cities } }
        ) {
            items {
                sys {
                    ...SysFields
                }
                slug
                content {
                    ... on NewsInternalFeed {
                        ...NewsInternalFeedFields
                    }
                    ... on NewsExternalFeed {
                        ...NewsExternalFeedFields
                    }
                }
            }
        }
    }
    ${FRAGMENT_SYS}
    ${FRAGMENT_NEWS_INTERNAL_FEED}
    ${FRAGMENT_NEWS_EXTERNAL_FEED}
`;

export const NEWS_FEED_BY_SLUG = gql`
    query GetNewsMainBySlug($slug: String!, $preview: Boolean!, $locale: String) {
        newsFeedCollection(limit: 1, preview: $preview, locale: $locale, where: { slug: $slug }) {
            items {
                sys {
                    ...SysFields
                }
                slug
                content {
                    ... on NewsInternalFeed {
                        ...NewsInternalFeedFields
                        ...NewsInternalFeedDetailsFields
                    }
                }
            }
        }
    }
    ${FRAGMENT_SYS}
    ${FRAGMENT_NEWS_INTERNAL_FEED}
    ${FRAGMENT_NEWS_INTERNAL_FEED_DETAILS}
`;
