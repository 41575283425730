import React from "react";
import styled from "styled-components";

export const AvatarFrame = styled.div<{
    $loading?: boolean;
    $isLarge?: boolean;
    $isResponsive?: boolean;
    $hideBorder?: boolean;
}>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 3rem;
    width: ${p => (p.$isLarge ? "7rem" : "3rem")};
    height: ${p => (p.$isLarge ? "7rem" : "3rem")};
    width: ${p => p.$isResponsive && "100%"};
    height: ${p => p.$isResponsive && "100%"};
    border: ${p => !p.$hideBorder && "0.2rem solid" + p.theme.gray50};
    border-radius: 50%;
    color: ${p => p.theme.primary};
    background-color: ${p => (p.$loading ? p.theme.gray50 : p.theme.orange400)};
    box-shadow: ${p => !p.$hideBorder && p.theme.avatarShadow};
    font-size: ${p => (p.$isLarge ? "1.2rem" : "1rem")};
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    overflow: hidden;

    @media print {
        border-color: ${p => p.theme.white};
        background-color: ${p => p.theme.lightPrimary};
    }

    &::selection {
        background-color: ${p => p.theme.white};
    }
`;

const AvatarImage = styled.img`
    width: 100%;
    height: 100%;
`;

export interface AvatarProps {
    name?: string;
    profileImage?: string;
    isLarge?: boolean;
    isResponsive?: boolean;
    loading?: boolean;
    hideBorder?: boolean;
}

export const Avatar: React.FC<AvatarProps> = ({
    name,
    isLarge,
    isResponsive,
    loading,
    hideBorder,
    profileImage,
}) => {
    return (
        <AvatarFrame
            className="avatar"
            $loading={loading}
            $isLarge={isLarge}
            $isResponsive={isResponsive}
            $hideBorder={hideBorder}
        >
            {profileImage ? <AvatarImage src={profileImage} alt={name} /> : name}
        </AvatarFrame>
    );
};
