import React from "react";
import styled from "styled-components";
import Image from "next/image";
import { HeadingSLight, HeadingS } from "../../../css/typography";
import { useTranslation } from "next-i18next";

const RatingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.4rem 4rem;
    background-color: ${p => p.theme.gray100};

    @media ${p => p.theme.bp.m} {
        padding: 2.4rem 8rem;
    }
`;

const RatingsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 1rem;
    margin-top: 1.6rem;
`;

const RatingsButton = styled.button<{ $active?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    width: 10rem;
    background-color: ${p => p.theme.gray50};
    border: 0.1rem solid ${p => (p.$active ? p.theme.orange700 : "transparent")};
    transition: background-color 0.2s;
    will-change: background-color;

    @media (hover: hover) {
        &:hover {
            background-color: ${p => p.theme.gray200};
        }
    }

    &:focus-visible,
    &:active {
        background-color: ${p => p.theme.gray200};
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    pointer-events: none;
    margin-right: 0.8rem;
`;

export interface NewsRatingProps {
    session?: Auth.Session | null;
    allocation?: Record<number, string[]> | null;
    onSelect: (value: number) => void;
}

export const NewsRating: React.FC<NewsRatingProps> = ({ session, allocation, onSelect }) => {
    const { t } = useTranslation();

    const withRatingLimit = (rating: number): number | string => {
        return rating >= 999 ? "999+" : rating;
    };

    const isActive = (reactionId: number): boolean => {
        if (!allocation || !session) {
            return false;
        }

        return allocation[reactionId] && allocation[reactionId].includes(session.uid);
    };

    const getRatingCount = (reactionId: number): number => {
        if (!allocation || !allocation[reactionId]) {
            return 0;
        }

        return allocation[reactionId].length;
    };

    return (
        <RatingWrapper>
            <HeadingSLight>{t("ratingsMsg")}</HeadingSLight>
            <RatingsList>
                <RatingsButton title={t("like")} $active={isActive(0)} onClick={() => onSelect(0)}>
                    <ImageWrapper>
                        <Image src="/img/emoji/thumbs-up.png" width="26" height="26" priority />
                    </ImageWrapper>
                    <HeadingS>{withRatingLimit(getRatingCount(0))}</HeadingS>
                </RatingsButton>
                <RatingsButton
                    title={t("thoughtful")}
                    $active={isActive(1)}
                    onClick={() => onSelect(1)}>
                    <ImageWrapper>
                        <Image src="/img/emoji/thinking-face.png" width="26" height="26" priority />
                    </ImageWrapper>
                    <HeadingS>{withRatingLimit(getRatingCount(1))}</HeadingS>
                </RatingsButton>
                <RatingsButton
                    title={t("inspiring")}
                    $active={isActive(2)}
                    onClick={() => onSelect(2)}>
                    <ImageWrapper>
                        <Image src="/img/emoji/light-bulb.png" width="26" height="26" priority />
                    </ImageWrapper>
                    <HeadingS>{withRatingLimit(getRatingCount(2))}</HeadingS>
                </RatingsButton>
            </RatingsList>
        </RatingWrapper>
    );
};
