import contentfulClient from "./data/contentful";
import { defaultLocale } from "../language";

export const updateRating = (articleId: string, uid: string, reactionId: number) => {
    return fetch("/api/update/rating", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            articleId,
            uid,
            reactionId,
        }),
    });
};

export const handleUpdateRating = async (articleId: string, reactionId: number, uid: string) => {
    try {
        const entries = await contentfulClient.entry.getMany({
            query: { content_type: "newsRating", "fields.articleId": articleId },
        });

        let entry = entries.items[0];

        if (!entry) {
            entry = await contentfulClient.entry.create(
                { contentTypeId: "newsRating" },
                {
                    fields: {
                        articleId: { [defaultLocale]: articleId },
                        allocation: { [defaultLocale]: { [reactionId]: [uid] } },
                    },
                }
            );
        } else {
            const fields = entry.fields.allocation[defaultLocale];

            if (fields[reactionId] && fields[reactionId].includes(uid)) {
                const index = fields[reactionId].findIndex((entry: string) => entry === uid);
                fields[reactionId].splice(index, 1);
                entry.fields.allocation[defaultLocale] = fields;
            } else if (fields[reactionId]) {
                entry.fields.allocation[defaultLocale] = {
                    ...fields,
                    [reactionId]: [...fields[reactionId], uid],
                };
            } else {
                entry.fields.allocation[defaultLocale] = {
                    ...fields,
                    [reactionId]: [uid],
                };
            }

            entry = await contentfulClient.entry.update({ entryId: entry.sys.id }, entry);
        }

        await contentfulClient.entry.publish({ entryId: entry.sys.id }, entry);
    } catch (e) {
        throw new Error(e);
    }
};
