import React, { useEffect, useState } from "react";
import { NewsContext } from "./NewsContext";
import { getNewsEmergency, getNewsFilters } from "../../api/data/contentful/news";
import { NewsPopup } from "../../../layout/templates/news-popup/NewsPopup";
import { useRouter } from "next/router";
import { deleteParamFromQuery } from "../../router";
import { newsFromSlug } from "../../news";
import { getNewsArticleBySlug } from "../../api/data/contentful/news/main";
import { getNewsFeedBySlug } from "../../api/data/contentful/news/feed";
import { useSession } from "../../auth/client/SessionContext";
import { Imprint } from "../../../layout/atom/imprint/Imprint";

export const NEWS_TYPE_MAIN = "main";
export const NEWS_TYPE_FEED = "feed";

type SelectedFacets = Record<string, Record<string, string>>;

type NewsArticle = News.MainDetails | News.FeedDetails | News.Emergency | null;

interface NewsProviderProps {
    initialFacets?: Filters.Facets;
}

export const selectedFacetsToMap = (facets: Record<string, Record<string, string>>) => {
    return Object.keys(facets).reduce((map, facetKey) => {
        map[facetKey] = facets[facetKey][Object.keys(facets[facetKey])[0]];
        return map;
    }, {} as Record<string, string>);
};

export const NewsProvider: React.FC<NewsProviderProps> = ({ initialFacets, children }) => {
    const router = useRouter();
    const { s } = router.query;
    const { session } = useSession();
    const [alert, setAlert] = useState<News.Emergency | null>(null);
    const [activeArticle, setActiveArticle] = useState<NewsArticle>(null);
    const [facets, setFacets] = useState<Filters.Facets>(initialFacets || {});
    const [selectedFacets, setSelectedFacets] = useState<SelectedFacets>({});

    useEffect(() => {
        if (initialFacets) {
            return;
        }

        getNewsFilters(router.locale).then(setFacets);
    }, []);

    useEffect(() => {
        if (!s || typeof s !== "string" || s === "imprint") {
            setActiveArticle(null);
            return;
        }

        const resultFromSlug = newsFromSlug(s);

        if (!resultFromSlug) {
            return;
        }

        const { type, slug } = resultFromSlug;

        switch (type) {
            case NEWS_TYPE_MAIN:
                getNewsArticleBySlug(slug, router.isPreview, router.locale).then(setActiveArticle);
                break;
            case NEWS_TYPE_FEED:
                getNewsFeedBySlug(slug, router.isPreview, router.locale).then(setActiveArticle);
                break;
        }
    }, [s]);

    useEffect(() => {
        if (!session) {
            return;
        }

        getNewsEmergency(session.location, router.isPreview, router.locale).then(setAlert);
    }, [session]);

    useEffect(() => {
        const keys = Object.keys(router.query).filter(queryKey => {
            return Object.keys(facets).includes(queryKey);
        });

        setSelectedFacets(
            keys.reduce((map, key) => {
                const value = router.query[key];

                if (!value || typeof value !== "string") {
                    return map;
                }

                map[key] = { [value]: facets[key].options[value] };

                return map;
            }, {} as SelectedFacets)
        );
    }, [facets, router.query]);

    const handleSelectFacet = (facetKey: string, value: string) => {
        const newQuery = { ...router.query, [facetKey]: value };

        if (facetKey === "month" && !newQuery["year"]) {
            newQuery.year = new Date().getFullYear().toString();
        }

        return pushRouterWithQuery(newQuery);
    };

    const handleDeleteFacet = (facetKey: string) => {
        const query = deleteParamFromQuery(router.query, facetKey);
        return pushRouterWithQuery(query);
    };

    const handleReset = () => {
        let query = router.query;

        Object.keys(selectedFacets).forEach(facetKey => {
            query = deleteParamFromQuery(query, facetKey);
        });

        return pushRouterWithQuery(query);
    };

    const showActiveAlert = () => {
        if (!alert) {
            return;
        }

        setActiveArticle(alert);
    };

    const closeActiveArticle = () => {
        setActiveArticle(null);
        const query = deleteParamFromQuery(router.query, "s");
        return pushRouterWithQuery(query);
    };

    const pushRouterWithQuery = (query: NodeJS.Dict<string | string[]>) => {
        return router.push({ query }, undefined, { shallow: true });
    };

    return (
        <NewsContext.Provider
            value={{
                facets,
                selectedFacets,
                categories: selectedFacets["category"] || facets["category"]?.options || {},
                handleSelectFacet,
                handleDeleteFacet,
                handleReset,
                alert,
                showActiveAlert,
            }}>
            {children}
            {s === "imprint" && <Imprint onClose={closeActiveArticle} />}
            {((s && typeof s === "string" && s !== "imprint") || activeArticle) && (
                <NewsPopup article={activeArticle} onClose={closeActiveArticle} />
            )}
        </NewsContext.Provider>
    );
};
