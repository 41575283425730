import {
    ApolloClient,
    ApolloLink,
    concat,
    HttpLink,
    InMemoryCache,
    QueryOptions,
} from "@apollo/client";
import { options } from "./cache/options";
import { deliveryConfig } from "./config/contentful";
import { backendConfig } from "./config/backend";

const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {}, token = "", preview = false, organization = false }) => {
        return {
            uri: organization
                ? `${backendConfig.host}/graphql`
                : `${deliveryConfig.host}/${deliveryConfig.spaceId}/environments/${deliveryConfig.environmentId}`,
            headers: {
                ...headers,
                authorization: `Bearer ${
                    token || (preview ? deliveryConfig.previewApiKey : deliveryConfig.apiKey)
                }`,
            },
        };
    });

    return forward(operation);
});

const client = new ApolloClient({
    ssrMode: typeof window === "undefined",
    cache: new InMemoryCache(options),
    link: concat(authMiddleware, new HttpLink()),
});

export const handleQuery = async <T>(queryOptions: QueryOptions): Promise<T> => {
    const { error, data } = await client.query<T>(queryOptions);

    if (error) {
        console.log(error);
        throw new Error(error.message);
    }

    return data;
};

export default client;
