import { gql } from "@apollo/client";

export const FRAGMENT_SYS = gql`
    fragment SysFields on Sys {
        id
        firstPublishedAt
    }
`;

export const FRAGMENT_ASSET = gql`
    fragment AssetFields on Asset {
        sys {
            ...SysFields
        }
        title
        url
        width
        height
        contentType
    }
    ${FRAGMENT_SYS}
`;

export const FRAGMENT_BASIC_LABELED_LINK = gql`
    fragment BasicLabeledLinkFields on BasicLabeledLink {
        label
        url
    }
`;
