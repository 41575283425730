import { handleQuery } from "../../../apollo";
import {
    NEWS_AUTO_SUGGESTIONS,
    NEWS_EMERGENCY,
    NEWS_FILTERS,
    NEWS_RATING_BY_ARTICLE,
} from "./query";
import { OperationVariables, useQuery } from "@apollo/client";
import { matchArray, removeDuplicatesBySysId, sysToFacet } from "../../../index";
import { isoDateRangeByMonthAndYear, localizedMonths } from "../../../../date";
import {
    NEWS_MAIN,
    NEWS_MAIN_BY_IDENTIFIERS,
    NEWS_MAIN_IDENTIFIERS_BY_LOCATION,
    NEWS_MAIN_IDENTIFIERS_BY_MAIN_TAG,
} from "./main/query";
import { locales } from "../../../../language";

interface NewsFilterCollection {
    newsCategoryCollection: Contentful.Collection<News.Category>;
    newsMainTagCollection: Contentful.Collection<News.MainTag>;
    contentTypeLocationCollection: Contentful.Collection<Common.Location>;
}

interface NewsSuggestionsCollection {
    localizedNewsExternalArticleCollection: Contentful.Collection<
        Contentful.LinkedFrom<{ newsMainCollection: Contentful.Collection<News.Suggestion> }>
    >;
    localizedNewsArticleCollection: Contentful.Collection<
        Contentful.LinkedFrom<{ newsMainCollection: Contentful.Collection<News.Suggestion> }>
    >;
    newsArticleCollection: Contentful.Collection<
        Contentful.LinkedFrom<{ newsMainCollection: Contentful.Collection<News.Suggestion> }>
    >;
    newsExternalArticleCollection: Contentful.Collection<
        Contentful.LinkedFrom<{ newsMainCollection: Contentful.Collection<News.Suggestion> }>
    >;
}

interface LocationCollection {
    contentTypeLocationCollection: Contentful.Collection<
        Contentful.LinkedFrom<News.MainCollection>
    >;
}

interface NewsMainTagCollection {
    newsMainTagCollection: Contentful.Collection<Contentful.LinkedFrom<News.MainCollection>>;
}

export const getNewsFilters = async (locale?: string): Promise<Filters.Facets> => {
    try {
        const { newsCategoryCollection, newsMainTagCollection, contentTypeLocationCollection } =
            await handleQuery<NewsFilterCollection>({
                query: NEWS_FILTERS,
                variables: { locale },
            });

        const months = localizedMonths(locale);
        const year = new Date().getFullYear();

        return {
            category: {
                title: "category",
                options: sysToFacet(newsCategoryCollection.items, "title"),
            },
            mainTag: {
                title: "mainTag",
                options: sysToFacet(newsMainTagCollection.items, "title"),
            },
            location: {
                title: "location",
                options: sysToFacet(contentTypeLocationCollection.items, "city", "city"),
            },
            month: {
                title: "month",
                options: Object.keys(months).reduce((map, monthKey) => {
                    map[months[parseInt(monthKey)]] = monthKey;
                    return map;
                }, {} as Record<string, string>),
            },
            year: {
                title: "year",
                options: Array.from(Array(year + 1).keys())
                    .splice(2021, year)
                    .reduce((map, year) => {
                        map[year.toString()] = year.toString();
                        return map;
                    }, {} as Record<string, string>),
            },
        };
    } catch (error) {
        return {};
    }
};

export const getNewsEmergency = async (location: string, preview: boolean, locale?: string) => {
    try {
        const { newsEmergencyCollection } = await handleQuery<News.EmergencyCollection>({
            query: NEWS_EMERGENCY,
            variables: { locale, preview, location },
            context: {
                preview,
            },
        });

        return newsEmergencyCollection.items[0] || null;
    } catch (error) {
        return null;
    }
};

export const filterNewsByStr = async (query: string, preview?: boolean, locale?: string) => {
    try {
        const {
            newsArticleCollection,
            newsExternalArticleCollection,
            localizedNewsArticleCollection,
            localizedNewsExternalArticleCollection,
        } = await handleQuery<NewsSuggestionsCollection>({
            query: NEWS_AUTO_SUGGESTIONS,
            variables: { query, locale, preview, allowedLocales: locales },
            context: { preview },
        });

        return removeDuplicatesBySysId([
            ...newsArticleCollection.items.flatMap(
                item => item.linkedFrom.newsMainCollection.items
            ),
            ...newsExternalArticleCollection.items.flatMap(
                item => item.linkedFrom.newsMainCollection.items
            ),
            ...localizedNewsArticleCollection.items.flatMap(
                item => item.linkedFrom.newsMainCollection.items
            ),
            ...localizedNewsExternalArticleCollection.items.flatMap(
                item => item.linkedFrom.newsMainCollection.items
            ),
        ]);
    } catch (error) {
        return null;
    }
};

export const getNewsRatingByArticle = async (articleId: string) => {
    try {
        const { newsRatingCollection } = await handleQuery<News.RatingCollection>({
            query: NEWS_RATING_BY_ARTICLE,
            variables: { articleId },
            fetchPolicy: "no-cache",
        });

        return newsRatingCollection.items[0] || null;
    } catch (error) {
        return null;
    }
};

export const getNewsTeaser = async (
    { category, locale, location }: OperationVariables,
    preview?: boolean
): Promise<News.MainCollection | null> => {
    try {
        let identifiers: string[] = [];

        if (location) {
            const { contentTypeLocationCollection } = await handleQuery<LocationCollection>({
                query: NEWS_MAIN_IDENTIFIERS_BY_LOCATION,
                variables: { location, preview },
                context: { preview },
            });

            const locationCollection = contentTypeLocationCollection.items[0];

            if (locationCollection) {
                identifiers = [...(locationCollection.linkedFrom.newsMainCollection.items || [])]
                    .sort(
                        (a, b) =>
                            Date.parse(b.sys.firstPublishedAt) - Date.parse(a.sys.firstPublishedAt)
                    )
                    .map(item => item.sys.id)
                    .slice(0, 200);
            }
        }

        let results: News.MainCollection;

        if (location) {
            results = await handleQuery<News.MainCollection>({
                query: NEWS_MAIN_BY_IDENTIFIERS,
                variables: {
                    limit: 4,
                    skip: 0,
                    locale,
                    identifiers,
                    preview,
                    category,
                },
                context: { preview },
            });
        } else {
            results = await handleQuery<News.MainCollection>({
                query: NEWS_MAIN,
                variables: {
                    limit: 4,
                    skip: 0,
                    locale,
                    category,
                    preview,
                },
                context: { preview },
            });
        }

        return results;
    } catch (error) {
        return null;
    }
};

export const useNewsArticles = (
    { limit, skip, category, locale, location, mainTag, month, page, year }: OperationVariables,
    preview?: boolean
) => {
    const { data: locationData, loading: locationLoading } = useQuery<LocationCollection>(
        NEWS_MAIN_IDENTIFIERS_BY_LOCATION,
        {
            skip: !location,
            variables: { location, preview },
            context: { preview },
        }
    );

    const locationCollection = locationData?.contentTypeLocationCollection.items[0];
    const locationIdentifiers =
        [...(locationCollection?.linkedFrom.newsMainCollection.items || [])]
            .filter(item => !!item?.sys?.id && !!item?.sys?.firstPublishedAt)
            .sort((a, b) => Date.parse(b.sys.firstPublishedAt) - Date.parse(a.sys.firstPublishedAt))
            .map(item => item.sys.id) ?? [];

    const { data: mainTagData, loading: mainTagLoading } = useQuery<NewsMainTagCollection>(
        NEWS_MAIN_IDENTIFIERS_BY_MAIN_TAG,
        {
            skip: !mainTag,
            variables: { mainTag, preview },
            context: { preview },
        }
    );

    const mainTagCollection = mainTagData?.newsMainTagCollection.items[0];
    const mainTagIdentifiers =
        [...(mainTagCollection?.linkedFrom.newsMainCollection.items || [])]
            .sort((a, b) => Date.parse(b.sys.firstPublishedAt) - Date.parse(a.sys.firstPublishedAt))
            .map(item => item.sys.id) ?? [];

    const identifiers = matchArray(locationIdentifiers, mainTagIdentifiers).slice(0, 200);

    const {
        data: identifiersResults,
        loading: identifiersLoading,
        refetch: identifiersRefetch,
    } = useQuery<News.MainCollection>(NEWS_MAIN_BY_IDENTIFIERS, {
        skip: !location && !mainTag,
        variables: {
            limit,
            skip,
            locale,
            preview,
            identifiers,
            category,
            firstDay: isoDateRangeByMonthAndYear(month, year).firstDay,
            lastDay: isoDateRangeByMonthAndYear(month, year).lastDay,
        },
        context: { preview },
    });

    const {
        data: results,
        loading,
        refetch,
    } = useQuery<News.MainCollection>(NEWS_MAIN, {
        skip: location || mainTag,
        variables: {
            limit,
            skip,
            locale,
            preview,
            category,
            firstDay: isoDateRangeByMonthAndYear(month, year).firstDay,
            lastDay: isoDateRangeByMonthAndYear(month, year).lastDay,
        },
        context: { preview },
    });

    return {
        results: identifiersResults || results,
        loading: locationLoading || mainTagLoading || identifiersLoading || loading,
        refetch: (vars: OperationVariables) => {
            if (location || mainTag) {
                identifiersRefetch(vars);
            } else {
                refetch(vars);
            }
        },
    };
};
