import { handleQuery } from "../../../../apollo";
import { NEWS_FEED_BY_SLUG } from "./query";

export const getNewsFeedBySlug = async (
    slug: string,
    preview: boolean,
    locale?: string
): Promise<News.FeedDetails | null> => {
    try {
        const { newsFeedCollection } = await handleQuery<News.FeedDetailsCollection>({
            query: NEWS_FEED_BY_SLUG,
            variables: { slug, preview, locale },
            context: {
                preview,
            },
        });

        return newsFeedCollection.items[0] || null;
    } catch (error) {
        return null;
    }
};
