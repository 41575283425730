import { SUGGESTION_TYPE_NEWS } from "./search";

export const searchSuggestionsFromNews = (news: News.Suggestion[]): Search.Suggestion[] => {
    return news.flatMap(item => {
        if (!item.content || !item.content.title) {
            return [];
        }

        const suggestion: Search.Suggestion = {
            id: item.sys.id,
            title: item.content.title,
            slug: item.slug,
            link: item.content["__typename"] === "NewsExternalArticle" ? item.content.link : null,
            category: item.category ? item.category.title : "Feed",
            type: SUGGESTION_TYPE_NEWS,
        };

        return suggestion;
    });
};

export const newsFromSlug = (str: string) => {
    const type = str.match(/[^:]*/i);
    const slug = str.match(/[^:]*$/i);

    if (!type || !slug) {
        return null;
    }

    return {
        type: type[0],
        slug: slug[0],
    };
};
