import { SVGProps } from "react";

export const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 11.556" {...props}>
        <path
            d="M10.286 11.555l-.817-.8 4.331-4.4H0V5.199h13.8L9.469.823l.817-.824L16 5.777z"
            fill="currentColor"
        />
    </svg>
);
