import styled, { css } from "styled-components";

export const FlowText = css`
    font-size: 1.5rem;
    line-height: 1.25;
`;

export const BasicText = css`
    font-size: 1.7rem;
    line-height: 1.25;
`;

export const HeadingLLightStyles = css`
    font-size: 2.6rem;
    letter-spacing: -0.01rem;
    line-height: 1.2;
`;

export const HeadingMLightStyles = css`
    font-size: 2.1rem;
    letter-spacing: -0.01rem;
    line-height: 1.2;
`;

export const HeadingSLightStyles = css`
    font-size: 1.7rem;
    line-height: 1.2;
`;

export const TagStyles = css`
    font-size: 1.3rem;
    line-height: 1;
`;

export const NewsText = styled.span`
    font-size: 1.8rem;
    line-height: 1.4;
    letter-spacing: -0.01rem;
`;

export const HeadingXXLLight = styled.span`
    font-size: 4.2rem;
    line-height: 1.2;
    letter-spacing: -0.015rem;
`;

export const HeadingXXL = styled(HeadingXXLLight)`
    font-weight: 500;
`;

export const HeadingLLight = styled.span`
    ${HeadingLLightStyles};
`;

export const HeadingL = styled(HeadingLLight)`
    font-weight: 500;
`;

export const HeadingMLight = styled.span`
    ${HeadingMLightStyles};
`;

export const HeadingM = styled(HeadingMLight)`
    font-weight: 500;
`;

export const HeadingSLight = styled.span`
    ${HeadingSLightStyles};
`;

export const HeadingS = styled(HeadingSLight)`
    font-weight: 500;
`;

export const HeadingXSLight = styled.span`
    font-size: 1.5rem;
    line-height: 1.2;
`;

export const HeadingXS = styled(HeadingXSLight)`
    font-weight: 500;
`;

export const HeadingXXSLight = styled.span`
    font-size: 1.3rem;
    line-height: 1.2;
`;

export const HeadingXXS = styled(HeadingXXSLight)`
    font-weight: 500;
`;

export const Meta = styled.span`
    font-size: 1.1rem;
    letter-spacing: 0.05rem;
`;

export const Tag = styled.div`
    ${TagStyles};
`;
