import { createGlobalStyle } from "styled-components";
import { reset } from "./reset";
import { FlowText } from "./typography";
import { organization } from "./organization";

export const GlobalStyle = createGlobalStyle`
    ${reset};
    ${organization};
    
    body {
        font-family: Effra, Helvetica, Arial, sans-serif;
        background-color: ${p => p.theme.gray100};
        color: ${p => p.theme.gray900};
        visibility: hidden;
        hyphens: auto;
        overflow-wrap: break-word;
        word-break: break-word;
        ${FlowText};
        -webkit-print-color-adjust: exact;
    }
    
    .dark-theme {
        color-scheme: dark;
    }

    .render {
        body {
            visibility: visible;
        }
    }

    .nodes-container {
        position: absolute;
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }

    input,
    textarea,
    button {
        -webkit-tap-highlight-color: transparent;
    }
`;
