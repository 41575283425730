import styled from "styled-components";

export const Content = styled.div<{ $breakMobile?: boolean; $breakTablet?: boolean }>`
    ${p =>
        !p.$breakMobile &&
        `
    @media ${p.theme.bp.maxM} {
        margin: 0 2rem;
        width: calc(100% - 4rem);
    }
  `}

    ${p =>
        !p.$breakTablet &&
        `
    @media ${p.theme.bp.m} {
        max-width: 102.4rem;
        width: calc(100% - 10.8rem);
        margin: 0 auto;
    }
  `}

    ${p =>
        !p.$breakTablet &&
        `
    @media ${p.theme.bp.l} {
        max-width: 144rem;
        width: calc(100% - 14rem);
        margin: 0 auto;
    }
  `}
`;

export const aspectRatio = (value: number) => `
    position: relative;

    &::after {
        content: "";
        display: block;
        padding-bottom: ${100 * value}%;
    }
`;
