import React from "react";
import styled from "styled-components";
import { Tag } from "../../../css/typography";

const TagWrapper = styled(Tag)`
    display: inline-block;
    padding: 0.6rem 1rem;
    border-radius: 4rem;
    color: ${p => p.theme.primary};
    background-color: ${p => p.theme.orange400};

    @media print {
        background-color: ${p => p.theme.lightPrimary};
    }
`;

export const NewsTag: React.FC = ({ children }) => {
    return <TagWrapper>{children}</TagWrapper>;
};
