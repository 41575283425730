import { gql } from "@apollo/client";
import { FRAGMENT_ASSET, FRAGMENT_SYS } from "../fragment";

export const FRAGMENT_NEWS_CATEGORY = gql`
    fragment NewsCategoryFields on NewsCategory {
        sys {
            ...SysFields
        }
        title
    }
    ${FRAGMENT_SYS}
`;

export const FRAGMENT_NEWS_MAIN_TAG = gql`
    fragment NewsMainTagFields on NewsMainTag {
        sys {
            ...SysFields
        }
        title
    }
    ${FRAGMENT_SYS}
`;

export const FRAGMENT_NEWS_SUB_TAG = gql`
    fragment NewsSubTagFields on NewsSubTag {
        sys {
            ...SysFields
        }
        title
    }
    ${FRAGMENT_SYS}
`;

export const FRAGMENT_NEWS_TEMPLATE = gql`
    fragment NewsTemplateFields on NewsTemplate {
        mainImage {
            ...AssetFields
        }
    }
`;

export const FRAGMENT_LOCATION = gql`
    fragment LocationFields on ContentTypeLocation {
        sys {
            ...SysFields
        }
        city
        countryCode
    }
    ${FRAGMENT_SYS}
`;

export const FRAGMENT_IMAGE = gql`
    fragment ImageFields on BasicImage {
        sys {
            ...SysFields
        }
        imageUrl
        description
    }
    ${FRAGMENT_SYS}
`;

export const FRAGMENT_IMAGE_SLIDER = gql`
    fragment ImageSliderFields on BasicImageSlider {
        sys {
            ...SysFields
        }
        imagesCollection {
            items {
                ...ImageFields
            }
        }
    }
    ${FRAGMENT_SYS}
    ${FRAGMENT_IMAGE}
`;

export const FRAGMENT_NEWS_EMERGENCY = gql`
    fragment NewsEmergencyFields on NewsEmergency {
        sys {
            ...SysFields
        }
        title
        teaserText
        text {
            json
        }
        author {
            name
            avatar {
                ...AssetFields
            }
        }
    }
    ${FRAGMENT_ASSET}
    ${FRAGMENT_SYS}
`;
