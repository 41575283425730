import React from "react";
import styled from "styled-components";
import { HeadingLLightStyles, HeadingMLightStyles, NewsText } from "../../../css/typography";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { renderOptions } from "../../../lib/rte";

const TextWrapper = styled.div`
    h1,
    h2,
    h3 {
        margin-bottom: 1rem;
        font-weight: 500;
    }

    h1 {
        ${HeadingLLightStyles};
    }

    h2 {
        ${HeadingMLightStyles};
    }

    ul,
    ol {
        padding-left: 2rem;
    }

    ul {
        list-style-type: unset;
    }

    ol {
        list-style-type: decimal;
    }

    blockquote {
        position: relative;
        padding-left: 2rem;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0.5rem;
            height: 100%;
            border-radius: 1rem;
            background-color: ${p => p.theme.gray300};
        }
    }

    a {
        color: ${p => p.theme.orange700};
        text-decoration: underline;
    }

    p,
    ul,
    ol,
    blockquote,
    .rte-image,
    .rte-video {
        display: flow-root;

        + h1,
        + h2,
        + h3,
        + p,
        + ul,
        + ol,
        + blockquote,
        + .rte-image,
        + .rte-video {
            margin-top: 3rem;
        }
    }
`;

export interface BlockTextProps {
    text: Contentful.RTE | null;
}

export const BlockText: React.FC<BlockTextProps> = ({ text }) => {
    return (
        <TextWrapper>
            {text && (
                <NewsText>
                    {documentToReactComponents(text.json, renderOptions(text.links))}
                </NewsText>
            )}
        </TextWrapper>
    );
};
