import { gql } from "@apollo/client";
import { FRAGMENT_NEWS_MAIN, FRAGMENT_NEWS_MAIN_DETAILS } from "./fragment";

export const NEWS_ARTICLE_BY_SLUG = gql`
    query GetNewsMainBySlug($slug: String!, $preview: Boolean!, $locale: String) {
        newsMainCollection(limit: 1, preview: $preview, locale: $locale, where: { slug: $slug }) {
            items {
                ...NewsMainDetailsFields
            }
        }
    }
    ${FRAGMENT_NEWS_MAIN_DETAILS}
`;

export const NEWS_MAIN = gql`
    query GetNewsMain(
        $limit: Int!
        $skip: Int!
        $preview: Boolean
        $locale: String
        $category: String
        $firstDay: DateTime
        $lastDay: DateTime
    ) {
        newsMainCollection(
            limit: $limit
            skip: $skip
            locale: $locale
            preview: $preview
            order: [sys_firstPublishedAt_DESC]
            where: {
                category: { sys: { id: $category } }
                AND: [
                    { sys: { firstPublishedAt_gte: $firstDay } }
                    { sys: { firstPublishedAt_lte: $lastDay } }
                ]
            }
        ) {
            total
            limit
            skip
            items {
                ...NewsMainFields
            }
        }
    }
    ${FRAGMENT_NEWS_MAIN}
`;

export const NEWS_MAIN_BY_IDENTIFIERS = gql`
    query GetNewsMainByIdentifiers(
        $limit: Int!
        $skip: Int!
        $identifiers: [String]!
        $preview: Boolean
        $locale: String
        $category: String
        $firstDay: DateTime
        $lastDay: DateTime
    ) {
        newsMainCollection(
            limit: $limit
            skip: $skip
            locale: $locale
            preview: $preview
            order: [sys_firstPublishedAt_DESC]
            where: {
                sys: { id_in: $identifiers }
                category: { sys: { id: $category } }
                AND: [
                    { sys: { firstPublishedAt_gte: $firstDay } }
                    { sys: { firstPublishedAt_lte: $lastDay } }
                ]
            }
        ) {
            total
            limit
            skip
            items {
                ...NewsMainFields
            }
        }
    }
    ${FRAGMENT_NEWS_MAIN}
`;

export const NEWS_MAIN_IDENTIFIERS_BY_LOCATION = gql`
    query GetNewsArticlesByLocation($location: String!, $preview: Boolean) {
        contentTypeLocationCollection(
            preview: $preview
            where: { city_in: [$location] }
            limit: 1
        ) {
            items {
                linkedFrom {
                    newsMainCollection(limit: 1000) {
                        items {
                            sys {
                                id
                                firstPublishedAt
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const NEWS_MAIN_IDENTIFIERS_BY_MAIN_TAG = gql`
    query GetNewsArticlesByMainTag($mainTag: String!, $preview: Boolean) {
        newsMainTagCollection(preview: $preview, where: { sys: { id_in: [$mainTag] } }, limit: 1) {
            items {
                linkedFrom {
                    newsMainCollection(limit: 1000) {
                        items {
                            sys {
                                id
                                firstPublishedAt
                            }
                        }
                    }
                }
            }
        }
    }
`;
