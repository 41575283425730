import { defaultLocale } from "./language";

interface DateRange {
    firstDay?: string;
    lastDay?: string;
}

export const getTimestampFromTime = (date: string, locale?: string) => {
    const time = Date.parse(date);

    const diff = Date.now() - time;
    const diffDate = new Date(diff);
    const created = new Date(time);

    const totalHours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = diffDate.getUTCMinutes();
    const day = created.getDate();
    const month = created.getMonth() + 1;

    if (totalHours > 24) {
        return `${day}. ${localizedMonths(locale)[month]}`;
    } else if (totalHours > 0) {
        return `${totalHours} hour${totalHours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
        return "just now";
    }
};

export const getDateFromTime = (time: number | string, locale?: string) => {
    const date = new Date(time);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${localizedMonths(locale)[month]} ${day}, ${year}`;
};

export const isoDateRangeByMonthAndYear = (
    targetMonth?: string,
    targetYear?: string
): DateRange => {
    if (!targetMonth && !targetYear) {
        return { firstDay: undefined, lastDay: undefined };
    }

    const today = new Date();
    const year = targetYear ? parseInt(targetYear) || today.getFullYear() : today.getFullYear();

    const firstDay = new Date(year, targetMonth ? parseInt(targetMonth) - 1 : 0, 1);
    const lastDay = new Date(year, targetMonth ? parseInt(targetMonth) : 12, 0);

    return { firstDay: firstDay.toISOString(), lastDay: lastDay.toISOString() };
};

export const localizedMonths = (locale?: string): Record<number, string> => {
    if (!locale || !months[locale]) {
        return months[defaultLocale];
    }

    return months[locale];
};

export const months: Record<string, Record<number, string>> = {
    "en-US": {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
    },
    de: {
        1: "Januar",
        2: "Februar",
        3: "März",
        4: "April",
        5: "Mai",
        6: "Juni",
        7: "Juli",
        8: "August",
        9: "September",
        10: "Oktober",
        11: "November",
        12: "Dezember",
    },
    da: {
        1: "Januar",
        2: "Februar",
        3: "Marts",
        4: "April",
        5: "Maj",
        6: "Juni",
        7: "Juli",
        8: "August",
        9: "September",
        10: "Oktober",
        11: "November",
        12: "December",
    },
    hu: {
        1: "Január",
        2: "Február",
        3: "Március",
        4: "Április",
        5: "Május",
        6: "Június",
        7: "Július",
        8: "Augusztus",
        9: "Szeptember",
        10: "Október",
        11: "November",
        12: "December",
    },
    pl: {
        1: "Styczeń",
        2: "Luty",
        3: "Marzec",
        4: "Kwiecień",
        5: "Maj",
        6: "Czerwiec",
        7: "Lipiec",
        8: "Sierpień",
        9: "Wrzesień",
        10: "Październik",
        11: "Listopad",
        12: "Grudzień",
    },
};
