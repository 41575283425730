import React, { useEffect, useState } from "react";
import { NewsModal } from "../news-modal/NewsModal";
import styled from "styled-components";
import { HeadingXXL, NewsText } from "../../../css/typography";
import { Content } from "../../../css/content";
import { BlockText } from "../block-text/BlockText";
import { getImprintCollection } from "../../../lib/api/data/contentful/imprint";
import { useRouter } from "next/router";
import { defaultLocale } from "../../../lib/language";

const ImprintWrapper = styled.div``;

const ImprintOverline = styled.div`
    margin-bottom: 1.5rem;
    color: ${p => p.theme.orange700};
    letter-spacing: 0.05rem;
    text-align: center;
    text-transform: uppercase;

    &:last-child {
        margin-bottom: 0;
    }
`;

const ImprintTitle = styled(HeadingXXL)`
    display: block;
    margin: 0 auto 4rem;
    text-align: center;
    hyphens: none;

    @media ${p => p.theme.bp.m} {
        max-width: 95%;
    }

    @media ${p => p.theme.bp.l} {
        max-width: 70%;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

export interface ImprintProps {
    onClose: () => void;
}

export const Imprint: React.FC<ImprintProps> = ({ onClose }) => {
    const { isPreview, locale } = useRouter();
    const [entry, setEntry] = useState<Common.Imprint | null>(null);

    useEffect(() => {
        getImprintCollection(isPreview, locale).then(setEntry);
    }, []);

    return (
        <NewsModal onClose={onClose}>
            {entry && (
                <ImprintWrapper>
                    {entry.overline && <ImprintOverline>{entry.overline}</ImprintOverline>}
                    {entry.headline && <ImprintTitle>{entry.headline}</ImprintTitle>}
                    <Content>{entry.text && <BlockText text={entry.text} />}</Content>
                </ImprintWrapper>
            )}
        </NewsModal>
    );
};
