import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { OrganizationContext } from "./OrganizationContext";
import { getDepartmentQuery, getEmployeeQuery } from "../../chart";

interface OrganizationProviderProps {
    organization?: Organization.Index;
}

export const OrganizationProvider: React.FC<OrganizationProviderProps> = ({
    organization,
    children,
}) => {
    const router = useRouter();
    const { department: departmentParam, employee: employeeParam } = router?.query ?? {};
    const [department, setDepartment] = useState<Organization.Department | null>(null);
    const [employee, setEmployee] = useState<Organization.Employee | null>(null);
    const [isChart, setIsChart] = useState(true);

    const departments = organization ? organization.departments : [];
    const positions = organization ? organization.positions : [];
    const employees = organization ? organization.employees : [];

    useEffect(() => {
        if (departmentParam) {
            if (department && department.id === departmentParam) {
                return;
            }

            if (typeof departmentParam === "string") {
                return updateDepartmentById(departmentParam);
            }
        } else {
            updateDepartmentById(null);
        }
    }, [departmentParam]);

    useEffect(() => {
        if (employeeParam) {
            if (employee && employee.id === employeeParam) {
                return;
            }

            if (typeof employeeParam === "string") {
                return updateEmployeeId(employeeParam);
            }
        } else {
            if (employee) {
                setEmployee(null);
            } else {
                updateEmployeeId(null);
            }
        }
    }, [employeeParam]);

    const resetSelection = () => {
        setDepartment(null);
        setEmployee(null);
    };

    const updateDepartmentById = (id?: string | null) => {
        if (!id) {
            return resetSelection();
        }

        const department = departments.find(department => department.id === id);

        if (!department) {
            return resetSelection();
        }

        setDepartment(department);
        setEmployee(null);
    };

    const updateEmployeeId = (id?: string | null) => {
        if (!id) {
            return resetSelection();
        }

        const employee = employees.find(employee => employee.id === id);

        if (!employee) {
            return resetSelection();
        }

        const position = employee.positions[0];

        if (!position) {
            return setEmployee(employee);
        }

        const department = departments.find(department =>
            department.positions?.find(pos => pos.id === position.positionId)
        );

        if (!department) {
            return setEmployee(employee);
        }

        setDepartment(department);
        setEmployee(employee);
    };

    const setDepartmentById = (id?: string | null) => {
        router.push(getDepartmentQuery(id), undefined, {
            shallow: true,
        });
    };

    const setEmployeeById = (id?: string | null) => {
        router.push(getEmployeeQuery(id), undefined, {
            shallow: true,
        });
    };

    return (
        <OrganizationContext.Provider
            value={{
                index: { departments, positions, employees },
                department,
                setDepartmentById,
                departments,
                positions,
                employee,
                employees,
                setEmployeeById,
                isChart,
                setIsChart,
            }}
        >
            {children}
        </OrganizationContext.Provider>
    );
};
