import styled from "styled-components";
import Image from "next/image";
import { resizeContentfulImage } from "./image";
import { Options } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import React from "react";

const Video = styled.video`
    width: 100%;
`;

export const renderOptions = (links: any): Options => {
    const assetMap = new Map();

    if (links) {
        for (const asset of links.assets.block) {
            assetMap.set(asset.sys.id, asset);
        }
    }

    return {
        renderText: text => text.split("\n").flatMap((text, i) => [i > 0 && <br key={i} />, text]),
        renderNode: {
            // eslint-disable-next-line react/display-name
            [INLINES.HYPERLINK]: (node, children) => (
                <a href={node.data.uri} target="_blank" rel="noreferrer noopener">
                    {children}
                </a>
            ),
            [BLOCKS.EMBEDDED_ASSET]: node => {
                const asset = assetMap.get(node.data.target.sys.id);

                if (asset.contentType.includes("image")) {
                    return (
                        <div className="rte-image">
                            <Image
                                src={resizeContentfulImage(asset.url, { width: 1000 })}
                                width={asset.width}
                                height={asset.height}
                                alt={asset.title}
                            />
                        </div>
                    );
                } else if (asset.contentType.includes("video")) {
                    return (
                        <div className="rte-video">
                            <Video src={asset.url + "#t=0.1"} controls preload="metadata" />
                        </div>
                    );
                }
            },
        },
    };
};
