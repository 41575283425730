import React from "react";
import styled from "styled-components";
import { ArrowRight } from "../../../icon/ArrowRight";

const ControlsWrapper = styled.div`
    display: flex;
`;

const ArrowIcon = styled(ArrowRight)`
    width: 1.8rem;
    height: 1.3rem;
`;

const ActionButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;

    &:first-child {
        background-color: ${p => p.theme.gray300};

        ${ArrowIcon} {
            transform: scaleX(-1);
        }
    }

    &:last-child {
        background-color: ${p => p.theme.gray200};
    }
`;

export interface SliderControlsProps {
    onPrev: () => void;
    onNext: () => void;
}

export const SliderControls: React.FC<SliderControlsProps> = ({ onNext, onPrev }) => {
    return (
        <ControlsWrapper>
            <ActionButton type="button" title="Next Slide" onClick={onPrev}>
                <ArrowIcon />
            </ActionButton>
            <ActionButton type="button" title="Next Slide" onClick={onNext}>
                <ArrowIcon />
            </ActionButton>
        </ControlsWrapper>
    );
};
