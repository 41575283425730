export const deliveryConfig = {
    host: "https://graphql.contentful.com/content/v1/spaces",
    spaceId: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
    apiKey: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
    previewApiKey: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_ACCESS_TOKEN,
    environmentId: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT,
};

export const managementConfig = {
    spaceId: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
    apiKey: process.env.NEXT_PUBLIC_CONTENTFUL_MANAGEMENT_ACCESS_TOKEN,
    environmentId: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT,
};
