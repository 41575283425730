import React, { useEffect, useState } from "react";
import Head from "next/head";
import { SessionContext } from "./SessionContext";
import { fetchData } from "../util/fetch";

interface SessionProviderProps {
    initialSession?: Auth.Session;
    redirectUrl?: string;
}

export const SessionProvider: React.FC<SessionProviderProps> = ({
    initialSession,
    redirectUrl,
    children,
}) => {
    const [session, setSession] = useState<Auth.Session | null>(initialSession || null);
    const [loading, setLoading] = useState<boolean>(!initialSession);

    useEffect(() => {
        if (initialSession) {
            return;
        }

        fetchData<Auth.Session>("session").then(res => {
            setSession(res);
            setLoading(false);
        });
    }, [initialSession]);

    return (
        <SessionContext.Provider value={{ session, loading }}>
            {process.env.NODE_ENV === "production" ? (
                <Head>
                    <script id="document-render">
                        {`
                        if (!document.cookie || document.cookie.indexOf('fox.authenticated=') === -1) {
                            location.replace("${redirectUrl}")
                        } else {
                            document.documentElement.classList.add('render')
                        }`}
                    </script>
                </Head>
            ) : (
                <Head>
                    <script id="document-render">{`document.documentElement.classList.add('render');`}</script>
                </Head>
            )}
            {children}
        </SessionContext.Provider>
    );
};
