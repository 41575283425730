import React from "react";
import Script from "next/script";

export const Matomo: React.FC = () => {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV !== "production") {
        return null;
    }

    return (
        <Script id="matomo" strategy="afterInteractive">
            {`
                var _paq = window._paq = window._paq || [];
                _paq.push(['trackPageView']);
                _paq.push(['enableLinkTracking']);
                (function() {
                    var u="https://fallerpackaging.matomo.cloud/";
                    _paq.push(['setTrackerUrl', u+'matomo.php']);
                    _paq.push(['setSiteId', '1']);
                    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                    g.async=true; g.src='//cdn.matomo.cloud/fallerpackaging.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
                })();
            `}
        </Script>
    );
};
