import { gql } from "@apollo/client";
import { FRAGMENT_SYS } from "../fragment";
import {
    FRAGMENT_LOCATION,
    FRAGMENT_NEWS_CATEGORY,
    FRAGMENT_NEWS_EMERGENCY,
    FRAGMENT_NEWS_MAIN_TAG,
} from "./fragment";

export const LOCAL_NEWS_ID = "2lt2254rJnUfn3mIpEBXly";

export const NEWS_AUTO_SUGGESTIONS = gql`
    query GetNewsAutoSuggestions(
        $query: String!
        $allowedLocales: [String]!
        $preview: Boolean
        $locale: String
    ) {
        newsArticleCollection(
            limit: 10
            locale: $locale
            preview: $preview
            order: [sys_firstPublishedAt_DESC]
            where: { title_contains: $query }
        ) {
            items {
                linkedFrom(allowedLocales: $allowedLocales) {
                    newsMainCollection(limit: 1) {
                        items {
                            sys {
                                ...SysFields
                            }
                            slug
                            content {
                                __typename
                                ... on NewsArticle {
                                    title
                                }
                            }
                            category {
                                ...NewsCategoryFields
                            }
                        }
                    }
                }
            }
        }
        newsExternalArticleCollection(
            limit: 10
            locale: $locale
            preview: $preview
            order: [sys_firstPublishedAt_DESC]
            where: { title_contains: $query }
        ) {
            items {
                linkedFrom(allowedLocales: $allowedLocales) {
                    newsMainCollection(limit: 1) {
                        items {
                            sys {
                                ...SysFields
                            }
                            slug
                            content {
                                __typename
                                ... on NewsExternalArticle {
                                    title
                                    link
                                }
                            }
                            category {
                                ...NewsCategoryFields
                            }
                        }
                    }
                }
            }
        }
        localizedNewsArticleCollection: newsArticleCollection(
            limit: 10
            preview: $preview
            order: [sys_firstPublishedAt_DESC]
            where: { title_contains: $query }
        ) {
            items {
                linkedFrom {
                    newsMainCollection(limit: 1) {
                        items {
                            sys {
                                ...SysFields
                            }
                            slug
                            content {
                                __typename
                                ... on NewsArticle {
                                    title
                                }
                            }
                            category {
                                ...NewsCategoryFields
                            }
                        }
                    }
                }
            }
        }
        localizedNewsExternalArticleCollection: newsExternalArticleCollection(
            limit: 10
            preview: $preview
            order: [sys_firstPublishedAt_DESC]
            where: { title_contains: $query }
        ) {
            items {
                linkedFrom {
                    newsMainCollection(limit: 1) {
                        items {
                            sys {
                                ...SysFields
                            }
                            slug
                            content {
                                __typename
                                ... on NewsExternalArticle {
                                    title
                                    link
                                }
                            }
                            category {
                                ...NewsCategoryFields
                            }
                        }
                    }
                }
            }
        }
    }
    ${FRAGMENT_SYS}
    ${FRAGMENT_NEWS_CATEGORY}
`;

export const NEWS_RATING_BY_ARTICLE = gql`
    query GetNewsRating($articleId: String!) {
        newsRatingCollection(limit: 1, where: { articleId: $articleId }) {
            items {
                articleId
                allocation
            }
        }
    }
`;

export const NEWS_EMERGENCY = gql`
    query GetNewsEmergency($location: String, $preview: Boolean!, $locale: String) {
        newsEmergencyCollection(
            limit: 1
            preview: $preview
            locale: $locale
            where: { OR: [{ location: { city: $location } }, { location_exists: false }] }
            order: [sys_firstPublishedAt_DESC]
        ) {
            items {
                ...NewsEmergencyFields
            }
        }
    }
    ${FRAGMENT_NEWS_EMERGENCY}
`;

export const NEWS_FILTERS = gql`
    query GetNewsFilters($locale: String) {
        newsCategoryCollection(locale: $locale) {
            items {
                ...NewsCategoryFields
            }
        }
        newsMainTagCollection(locale: $locale) {
            items {
                ...NewsMainTagFields
            }
        }
        contentTypeLocationCollection(locale: $locale, order: [city_ASC]) {
            items {
                ...LocationFields
            }
        }
    }
    ${FRAGMENT_NEWS_CATEGORY}
    ${FRAGMENT_NEWS_MAIN_TAG}
    ${FRAGMENT_LOCATION}
`;
