import React from "react";
import styled from "styled-components";
import { Close } from "../../../icon/Close";

const PopupWrapper = styled.div`
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: fade-in 0.3s forwards;

    @keyframes fade-in {
        100% {
            opacity: 1;
        }
    }

    @media print {
        position: static;
    }
`;

const PopupOverlay = styled.button`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${p => p.theme.overlay};
`;

const PopupFrame = styled.div`
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
    max-width: 100.5rem;
    background-color: ${p => p.theme.gray50};

    @media ${p => p.theme.bp.l} {
        height: 90%;
    }

    @media print {
        min-height: 100vh;
        height: auto;
        max-width: none;
        background-color: ${p => p.theme.white};
    }
`;

const PopupStage = styled.div`
    height: 100%;
    overflow-y: auto;
    padding: 14rem 0 8rem;

    @media ${p => p.theme.bp.m} {
        padding: 16rem 0 8rem;
    }

    @media ${p => p.theme.bp.l} {
        padding: 12rem 0 8rem;
    }

    @media print {
        padding: 0;
    }
`;

const ButtonClose = styled.button`
    position: absolute;
    z-index: 1;
    top: 2rem;
    right: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    background-color: ${p => p.theme.gray100};

    @media ${p => p.theme.bp.m} {
        top: 2.8rem;
        right: 2.8rem;
    }

    @media ${p => p.theme.bp.l} {
        top: 3rem;
        right: 3rem;
    }

    @media print {
        display: none;
    }
`;

const CloseIcon = styled(Close)`
    width: 1.4rem;
    height: 1.4rem;
`;

export interface NewsModalProps {
    onClose?: () => void;
}

export const NewsModal: React.FC<NewsModalProps> = ({ onClose, children }) => {
    return (
        <PopupWrapper>
            <PopupOverlay type="button" onClick={onClose} />
            <PopupFrame>
                <ButtonClose type="button" onClick={onClose}>
                    <CloseIcon />
                </ButtonClose>
                <PopupStage>{children}</PopupStage>
            </PopupFrame>
        </PopupWrapper>
    );
};
