import { SVGProps } from "react";

export const Close = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" {...props}>
        <path
            d="M12.4 14L7 8.6 1.6 14 0 12.4 5.4 7 0 1.6 1.6 0 7 5.4 12.4 0 14 1.6 8.6 7l5.4 5.4z"
            fill="currentColor"
        />
    </svg>
);
