import { handleQuery } from "../../../../apollo";
import { NEWS_ARTICLE_BY_SLUG } from "./query";

export const getNewsArticleBySlug = async (
    slug: string,
    preview: boolean,
    locale?: string
): Promise<News.MainDetails | null> => {
    try {
        const { newsMainCollection } = await handleQuery<News.MainDetailsCollection>({
            query: NEWS_ARTICLE_BY_SLUG,
            variables: { slug, preview, locale },
            context: {
                preview,
            },
        });

        return newsMainCollection.items[0] || null;
    } catch (error) {
        return null;
    }
};
