export const routes = {
    home: "/",
    news: "/news",
    media: "/media",
    organization: "/organization",
    serviceCenterFox: "/service-center",
    serviceCenterIt: "/service-center-it",
};

export const deleteParamFromQuery = (query: NodeJS.Dict<string | string[]>, selector: string) => {
    delete query[selector];
    return query;
};
