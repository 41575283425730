import React, { useEffect, useRef } from "react";
import KeenSlider, { TOptionsEvents } from "keen-slider";

export const useSlider = (
    ref: React.MutableRefObject<HTMLDivElement | null>,
    options: TOptionsEvents
) => {
    const sliderRef = useRef<KeenSlider | null>(null);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const slider = new KeenSlider(ref.current, options);
        sliderRef.current = slider;

        return () => slider.destroy();
    }, []);

    const handlePrev = () => {
        if (!sliderRef.current) {
            return;
        }

        sliderRef.current.prev();
    };

    const handleNext = () => {
        if (!sliderRef.current) {
            return;
        }

        sliderRef.current.next();
    };

    return {
        handleNext,
        handlePrev,
    };
};
