import React, { createContext, useContext, useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { dark, light } from "../../../css/theme";
import { GlobalStyle } from "../../../css/GlobalStyle";

export type Theme = "light" | "dark" | null;

export interface CustomThemeContextData {
    theme: Theme;
    toggleTheme: () => void;
    activeTheme: typeof dark | typeof light;
}

const CustomThemeContext = createContext<CustomThemeContextData>({} as CustomThemeContextData);

const STORAGE_THEME = "fox.theme";

export const CustomThemeProvider: React.FC = ({ children }) => {
    const [theme, setTheme] = useState<Theme>(null);
    const activeTheme = "dark" === theme ? dark : light;

    useEffect(() => {
        const value = localStorage.getItem(STORAGE_THEME);

        if ("light" === value || "dark" === value) {
            setTheme(value);
        } else if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
            setTheme("dark");
        }
    }, []);

    useEffect(() => {
        if (!theme) {
            return;
        }

        localStorage.setItem(STORAGE_THEME, theme);

        if (theme === "dark") {
            document.documentElement.classList.remove("light-theme");
            document.documentElement.classList.add("dark-theme");
        } else {
            document.documentElement.classList.remove("dark-theme");
            document.documentElement.classList.add("light-theme");
        }
    }, [theme]);

    const toggleTheme = () => {
        setTheme(prevState => {
            if ("light" === prevState) {
                return "dark";
            } else {
                return "light";
            }
        });
    };

    return (
        <CustomThemeContext.Provider value={{ theme, toggleTheme, activeTheme }}>
            <ThemeProvider theme={activeTheme}>
                <GlobalStyle />
                {children}
            </ThemeProvider>
        </CustomThemeContext.Provider>
    );
};

export const useCustomTheme = () => useContext(CustomThemeContext);
