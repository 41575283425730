import { gql } from "@apollo/client";
import { FRAGMENT_ASSET, FRAGMENT_SYS } from "../../fragment";
import {
    FRAGMENT_IMAGE,
    FRAGMENT_IMAGE_SLIDER,
    FRAGMENT_NEWS_CATEGORY,
    FRAGMENT_NEWS_MAIN_TAG,
    FRAGMENT_NEWS_SUB_TAG,
} from "../fragment";

export const FRAGMENT_NEWS_ARTICLE = gql`
    fragment NewsArticleFields on NewsArticle {
        title
        sys {
            ...SysFields
        }
        teaserText
        image {
            ...ImageFields
        }
        author {
            name
        }
    }
    ${FRAGMENT_SYS}
    ${FRAGMENT_IMAGE}
`;

export const FRAGMENT_NEWS_ARTICLE_DETAILS = gql`
    fragment NewsArticleDetailsFields on NewsArticle {
        imageSlider {
            ...ImageSliderFields
        }
        video
        rating
        text {
            json
            links {
                assets {
                    block {
                        ...AssetFields
                    }
                }
            }
        }
        author {
            name
            avatar {
                ...AssetFields
            }
        }
        readTime
    }
    ${FRAGMENT_SYS}
    ${FRAGMENT_ASSET}
    ${FRAGMENT_IMAGE_SLIDER}
`;

export const FRAGMENT_NEWS_EXTERNAL_ARTICLE = gql`
    fragment NewsExternalArticleFields on NewsExternalArticle {
        sys {
            ...SysFields
        }
        link
        title
        teaserText
        mainImage
    }
    ${FRAGMENT_SYS}
`;

export const FRAGMENT_NEWS_MAIN = gql`
    fragment NewsMainFields on NewsMain {
        sys {
            ...SysFields
        }
        slug
        content {
            ... on NewsArticle {
                ...NewsArticleFields
            }
            ... on NewsExternalArticle {
                ...NewsExternalArticleFields
            }
        }
        category {
            ...NewsCategoryFields
        }
        mainTagsCollection {
            items {
                ...NewsMainTagFields
            }
        }
        subTagsCollection {
            items {
                ...NewsSubTagFields
            }
        }
    }
    ${FRAGMENT_SYS}
    ${FRAGMENT_NEWS_ARTICLE}
    ${FRAGMENT_NEWS_EXTERNAL_ARTICLE}
    ${FRAGMENT_NEWS_CATEGORY}
    ${FRAGMENT_NEWS_MAIN_TAG}
    ${FRAGMENT_NEWS_SUB_TAG}
`;

export const FRAGMENT_NEWS_MAIN_DETAILS = gql`
    fragment NewsMainDetailsFields on NewsMain {
        sys {
            ...SysFields
        }
        slug
        content {
            ... on NewsArticle {
                ...NewsArticleFields
                ...NewsArticleDetailsFields
            }
        }
        category {
            ...NewsCategoryFields
        }
        mainTagsCollection {
            items {
                ...NewsMainTagFields
            }
        }
        subTagsCollection {
            items {
                ...NewsSubTagFields
            }
        }
    }
    ${FRAGMENT_SYS}
    ${FRAGMENT_NEWS_ARTICLE}
    ${FRAGMENT_NEWS_ARTICLE_DETAILS}
    ${FRAGMENT_NEWS_CATEGORY}
    ${FRAGMENT_NEWS_MAIN_TAG}
    ${FRAGMENT_NEWS_SUB_TAG}
`;
