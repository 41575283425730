export const breakpoints = {
    m: 768,
    t: 880,
    l: 1200,
    xl: 2000,
};

export const theme = {
    //region Color
    black: "#000",
    white: "#FFF",
    blue: "#1B4262",
    gray: "#BABABA",
    lightGray: "#F5F5F5",
    primary: "#EA5906",
    lightPrimary: "#FFE8D1",
    primaryBackground: "#FFF5F0",

    overlay: "rgba(0, 0, 0, 0.5)",

    primaryGradient: "transparent linear-gradient(90deg, #EA5906 0%, #F9AE28 100%) 0% 0% no-repeat",
    secondaryGradient:
        "transparent linear-gradient(90deg, #20313F 0%, #1B4262 100%) 0% 0% no-repeat",

    switchShadow: "0 0.1rem 0.6rem #00000029",
    cardShadow: "0 0.5rem 2rem rgba(0, 0, 0, 0.1)",
    avatarShadow: "0 0.3rem 0.3rem rgba(0, 0, 0, 0.03)",
    dropdownShadow: "0 0.2rem 2.4rem rgba(0, 0, 0, 0.15)",
    controlsShadow: "0 0.3rem 2rem rgba(0, 0, 0, 0.08)",
    //endregion

    //region Other
    navigationHeight: "5.6rem",
    //endregion

    //region Breakpoints
    bp: {
        m: `screen and (min-width: ${breakpoints.m}px)`,
        l: `screen and (min-width: ${breakpoints.l}px)`,
        xl: `screen and (min-width: ${breakpoints.xl}px)`,
        maxM: `screen and (max-width: ${breakpoints.m}px)`,
        maxL: `screen and (max-width: ${breakpoints.l}px)`,
        minMMaxT: `screen and (min-width: ${breakpoints.m}px) and (max-width: ${breakpoints.t}px)`,
    },
    //endregion
};

export const light = {
    ...theme,
    name: "default/light",

    //region Colors
    gray50: "#FFF",
    gray75: "#FAFAFA",
    gray100: "#F5F5F5",
    gray200: "#EAEAEA",
    gray300: "#E1E1E1",
    gray400: "#CACACA",
    gray500: "#B3B3B3",
    gray600: "#8E8E8E",
    gray700: "#6E6E6E",
    gray800: "#4B4B4B",
    gray900: "#2C2C2C",

    orange400: "#FFE8D1",
    orange500: "#F08C37",
    orange700: "#EA5906",
    orange800: "#DB5204",

    red400: "#E34850",
    red500: "#D7373F",
    red600: "#C9252D",
    red700: "#BB121A",

    blue400: "#1F3140",
    blue500: "#193750",
    blue600: "#1E4260",
    blue700: "#2A5980",

    green400: "#2D9D78",
    green500: "#268E6C",
    green600: "#12805C",
    green700: "#107154",
    //endregion
};

export const dark = {
    ...theme,
    name: "dark",

    //region Colors
    gray50: "#1E1E1E",
    gray75: "#1A1A1A",
    gray100: "#080808",
    gray200: "#2C2C2C",
    gray300: "#393939",
    gray400: "#494949",
    gray500: "#5C5C5C",
    gray600: "#7C7C7C",
    gray700: "#A2A2A2",
    gray800: "#C8C8C8",
    gray900: "#EFEFEF",

    orange400: "#3C2618",
    orange500: "#F08C37",
    orange700: "#EA5906",
    orange800: "#DB5204",

    red400: "#D7373F",
    red500: "#E34850",
    red600: "#EC5B62",
    red700: "#F76D74",

    blue400: "#1F3140",
    blue500: "#193750",
    blue600: "#1E4260",
    blue700: "#2A5980",

    green400: "#268E6C",
    green500: "#2D9D78",
    green600: "#33AB84",
    green700: "#39B990",
    //endregion
};

type Theme = typeof light & typeof dark;

declare module "styled-components" {
    export interface DefaultTheme extends Theme {}
}
