import React, { useRef } from "react";
import Image from "next/image";
import styled from "styled-components";
import { useSlider } from "../../../lib/slider";
import { SliderControls } from "../../atom/slider-controls/SilderControls";
import { aspectRatio } from "../../../css/content";

const SliderWrapper = styled.div``;

const SliderFrame = styled.div`
    display: flex;
    overflow: visible;
`;

const ImageWrapper = styled.div`
    position: relative;
    will-change: transform;
    ${aspectRatio(0.6)};
    background-color: ${p => p.theme.gray200};
`;

const ImageSlide = styled.div``;

const ControlsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 3rem;
`;

const NewsCaption = styled.div`
    color: ${p => p.theme.gray600};
    margin-top: 1.5rem;
`;

export const ImageSlider: React.FC<News.ImageSlider> = ({ imagesCollection }) => {
    const sliderRef = useRef<HTMLDivElement | null>(null);
    const { handlePrev, handleNext } = useSlider(sliderRef, {
        spacing: 30,
        rubberband: false,
        slidesPerView: 1,
    });

    const slides = imagesCollection.items;

    return (
        <SliderWrapper>
            {slides.length > 1 && (
                <ControlsWrapper>
                    <SliderControls onPrev={handlePrev} onNext={handleNext} />
                </ControlsWrapper>
            )}
            <SliderFrame ref={sliderRef} className="keen-slider">
                {slides.map((slide, index) => (
                    <ImageSlide key={index} className="keen-slider__slide">
                        <ImageWrapper>
                            {slide.imageUrl && (
                                <Image
                                    src={slide.imageUrl}
                                    alt="Slider image"
                                    layout="fill"
                                    objectFit="cover"
                                    sizes="800px"
                                />
                            )}
                        </ImageWrapper>
                        {slide.description && <NewsCaption>{slide.description}</NewsCaption>}
                    </ImageSlide>
                ))}
            </SliderFrame>
        </SliderWrapper>
    );
};
