import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NewsDetails } from "../../atom/news-details/NewsDetails";
import { Close } from "../../../icon/Close";
import { useSession } from "../../../lib/auth/client/SessionContext";
import { getNewsRatingByArticle } from "../../../lib/api/data/contentful/news";
import { updateRating } from "../../../lib/api/rating";
import { NewsModal } from "../../atom/news-modal/NewsModal";

export interface NewsPopupProps {
    article: News.MainDetails | News.FeedDetails | News.Emergency | null;
    onClose: () => void;
}

export const NewsPopup: React.FC<NewsPopupProps> = ({ article, onClose }) => {
    const { session } = useSession();
    const [ratingData, setRatingData] = useState<News.Rating | null>(null);

    const content = article && article["__typename"] === "NewsEmergency" ? null : article?.content;

    useEffect(() => {
        if (!content || !content.sys || !content.rating) {
            return;
        }

        getNewsRatingByArticle(content.sys.id).then(setRatingData);

        return () => setRatingData(null);
    }, [article]);

    const handleRating = (reactionId: number) => {
        if (!content || !content.sys || !content.rating || !session) {
            return;
        }

        const data = ratingData ? ratingData.allocation[reactionId] : null;
        let identifiers = data ? [...data] : null;

        if (identifiers && identifiers.includes(session.uid)) {
            const index = identifiers.findIndex(identifier => identifier === session.uid);
            identifiers.splice(index, 1);
        } else if (identifiers) {
            identifiers = [...identifiers, session.uid];
        } else {
            identifiers = [session.uid];
        }

        setRatingData(prevState => {
            if (!content || !content.sys || !identifiers) {
                return prevState || null;
            }

            if (!prevState) {
                return {
                    articleId: content.sys.id,
                    allocation: {
                        [reactionId]: identifiers,
                    },
                };
            }

            return {
                ...prevState,
                allocation: {
                    ...prevState.allocation,
                    [reactionId]: identifiers,
                },
            };
        });

        return updateRating(content.sys.id, session.uid, reactionId);
    };

    if (!article) return null;

    return (
        <NewsModal onClose={onClose}>
            <NewsDetails
                article={article}
                session={session}
                ratingData={ratingData}
                onRating={handleRating}
            />
        </NewsModal>
    );
};
