export const fallbackImage = "/img/fallback/news.png";

export const resizeContentfulImage = (
    url: string,
    { width, height }: { width?: number; height?: number }
) => {
    if (!width && !height) {
        return url;
    }

    const w = width ? `w=${width}` : "";
    const h = height ? `w=${height}` : "";

    return url + "?" + w + h;
};
