export const SEARCH_TAB_NEWS = "news";
export const SEARCH_TAB_ORGANIZATION = "organization";

export const SUGGESTION_TYPE_NEWS = "news";
export const SUGGESTION_TYPE_DEPARTMENT = "department";
export const SUGGESTION_TYPE_EMPLOYEE = "employee";

export const searchCategoriesFromSuggestions = (
    suggestions: Search.Suggestion[]
): Record<string, Search.Suggestion[]> => {
    return suggestions.reduce((map, suggestion) => {
        const category = suggestion.category;
        const arr = map[category] || [];
        map[category] = [...arr, suggestion];
        return map;
    }, {} as Record<string, Search.Suggestion[]>);
};

export const searchTabsFromSuggestions = (
    suggestions: Search.Suggestion[]
): Record<Search.Tab, Search.Suggestion[]> => {
    return {
        organization: suggestions.filter(
            suggestions =>
                suggestions.type === SUGGESTION_TYPE_DEPARTMENT ||
                suggestions.type === SUGGESTION_TYPE_EMPLOYEE
        ),
        news: suggestions.filter(suggestions => suggestions.type === SUGGESTION_TYPE_NEWS),
    };
};

export const activeTabFromPath = (path: string): Search.Tab | undefined => {
    const tabMatch = path.match(/[^/]*$/i);

    if (!tabMatch) {
        return;
    }

    const tab = tabMatch[0];

    if (!tab || (tab !== SEARCH_TAB_NEWS && tab !== SEARCH_TAB_ORGANIZATION)) {
        return;
    }

    return tab;
};
