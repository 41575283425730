export const bundleArticleText = (
    article: News.Emergency | News.ArticleDetails | News.InternalFeedDetails
) => {
    if (!article || !article.text) {
        return "";
    }
    return article.text.json?.content.reduce(
        (total: string, next: any) =>
            total +
            next?.content?.reduce((total: string, next: any) => total + next?.value ?? "", ""),
        ""
    );
};
export const calcReadTime = (wordCount: number) => Math.ceil(wordCount / 183);
