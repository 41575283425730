import { gql } from "@apollo/client";
import { FRAGMENT_ASSET } from "../fragment";

export interface ImprintCollection {
    imprintCollection: Contentful.Collection<Common.Imprint>;
}

export const IMPRINT_COLLECTION = gql`
    query getImprint($preview: Boolean!, $locale: String) {
        imprintCollection(
            limit: 1
            locale: $locale
            preview: $preview
            order: [sys_firstPublishedAt_DESC]
        ) {
            items {
                overline
                headline
                text {
                    json
                    links {
                        assets {
                            block {
                                ...AssetFields
                            }
                        }
                    }
                }
            }
        }
    }
    ${FRAGMENT_ASSET}
`;
