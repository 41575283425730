import React, { useMemo } from "react";
import styled from "styled-components";
import { HeadingXXL, HeadingM } from "../../../css/typography";
import { Avatar } from "../avatar/Avatar";
import { getDateFromTime } from "../../../lib/date";
import { NewsRating } from "../news-rating/NewsRating";
import { Content } from "../../../css/content";
import { NewsTag } from "../news-tag/NewsTag";
import { ImageSlider } from "../../molecule/image-slider/ImageSlider";
import { BlockText } from "../block-text/BlockText";
import { fallbackImage } from "../../../lib/image";
import { useRouter } from "next/router";
import { Printer } from "../../../icon/Printer";
import { calcReadTime } from "../../../lib/util";
import { bundleArticleText } from "../../../lib/article";

const NewsWrapper = styled.div`
    overflow: hidden;
`;

const NewsImageWrapper = styled.div`
    margin-top: 4rem;

    @media print {
        display: none;
    }
`;

const NewsImageFrame = styled.div`
    display: flex;
    background-color: ${p => p.theme.gray200};
`;

const NewsImage = styled.img`
    width: 100%;
`;

const NewsCaption = styled.div`
    color: ${p => p.theme.gray600};
    margin-top: 1.5rem;
`;

const NewsOverline = styled.div`
    color: ${p => p.theme.orange700};
    letter-spacing: 0.05rem;
    text-align: center;
    text-transform: uppercase;
`;

export const NewsTitle = styled(HeadingXXL)`
    display: block;
    margin: 1.5rem auto 0;
    text-align: center;
    hyphens: none;

    @media ${p => p.theme.bp.m} {
        max-width: 95%;
    }

    @media ${p => p.theme.bp.l} {
        max-width: 70%;
    }

    @media print {
        color: ${p => p.theme.black};
    }
`;

const NewsDesc = styled(HeadingM)`
    text-align: center;
    margin: 1.5rem auto 0;

    @media ${p => p.theme.bp.m} {
        max-width: 65%;
    }

    @media print {
        color: ${p => p.theme.black};
    }
`;

const NewsDate = styled.div`
    grid-area: date;
    text-align: center;
    color: ${p => p.theme.gray600};
    transition: color 0.2s;

    @media (hover: hover) {
        &:hover {
            border-color: ${p => p.theme.gray600};
            color: ${p => p.theme.orange700};
        }
    }
`;

const NewsInfo = styled.div`
    display: grid;
    grid-template-areas:
        "date"
        "author"
        "time";

    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    margin-top: 2rem;

    @media ${p => p.theme.bp.m} {
        grid-template-areas:
            "author date"
            "time time";

        grid-template-columns: auto auto;
    }
`;

const TextWrapper = styled.div`
    margin: 4rem auto 0;

    @media ${p => p.theme.bp.m} {
        max-width: 70rem;
    }

    @media print {
        color: ${p => p.theme.black};
    }
`;

const NewsAuthor = styled.div`
    grid-area: author;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    gap: 0.8rem 1rem;

    @media ${p => p.theme.bp.m} {
        text-align: start;
    }

    @media print {
        color: ${p => p.theme.black};
    }
`;

const NewsReadTime = styled(NewsDate)`
    grid-area: time;
    text-align: center;
`;

const NewsRatingWrapper = styled.div`
    margin: 9rem auto 0;

    @media ${p => p.theme.bp.l} {
        max-width: 70rem;
    }

    @media print {
        display: none;
    }
`;

const ImageSliderWrapper = styled.div`
    margin: 4rem auto 0;

    @media ${p => p.theme.bp.l} {
        max-width: 70rem;
    }

    @media print {
        display: none;
    }
`;

const ArticleVideo = styled.video`
    width: 100%;
    margin: 4rem auto 0;

    @media ${p => p.theme.bp.l} {
        max-width: 70rem;
    }

    @media print {
        display: none;
    }
`;

const NewsFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 70rem;
    margin: 3rem auto 0;
`;

const TagsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
`;

const NewsPrint = styled.button`
    color: ${p => p.theme.primary};
    margin-left: 2rem;

    &:last-child {
        margin-left: 0;
    }

    @media print {
        display: none;
    }
`;

const IconPrint = styled(Printer)`
    width: 2.2rem;
    height: 2.2rem;
`;

export interface NewsDetailsProps {
    article: News.MainDetails | News.FeedDetails | News.Emergency;
    session?: Auth.Session | null;
    ratingData?: News.Rating | null;
    onRating?: (reactionId: number) => void;
}

export const NewsDetails: React.FC<NewsDetailsProps> = ({
    article,
    session,
    ratingData,
    onRating,
}) => {
    const router = useRouter();

    const content = article["__typename"] === "NewsEmergency" ? article : article.content;

    const mainTagsCollection = useMemo(() => {
        switch (article["__typename"]) {
            case "NewsFeed":
                if (!article.content) {
                    return null;
                }

                return article.content.mainTagsCollection;
            case "NewsMain":
                return article.mainTagsCollection;
        }
    }, [article]);

    const subTagsCollection = useMemo(() => {
        switch (article["__typename"]) {
            case "NewsFeed":
                if (!article.content) {
                    return null;
                }

                return article.content.subTagsCollection;
            case "NewsMain":
                return article.subTagsCollection;
        }
    }, [article]);

    const readTime = useMemo(() => {
        if (!content || !content.text) {
            return null;
        }

        if ("readTime" in content && content.readTime) {
            return content.readTime;
        }

        return calcReadTime(bundleArticleText(content).split(" ").length);
    }, [content]);

    if (!content) {
        return null;
    }

    return (
        <NewsWrapper>
            <Content>
                {mainTagsCollection &&
                    mainTagsCollection.items.map(tag => (
                        <NewsOverline key={tag.sys.id}>{tag.title}</NewsOverline>
                    ))}
                {content.title && <NewsTitle as="h1">{content.title}</NewsTitle>}
                {content.teaserText && <NewsDesc as="h3">{content.teaserText}</NewsDesc>}
                <NewsInfo>
                    {content.sys && (
                        <NewsDate>
                            {getDateFromTime(
                                article.sys.firstPublishedAt || Date.now(),
                                router.locale
                            )}
                        </NewsDate>
                    )}
                    {content.author && (
                        <NewsAuthor>
                            <Avatar name={content.author.name} />
                            {content.author.name}
                        </NewsAuthor>
                    )}
                    {readTime && <NewsReadTime>{`${readTime}min Lesedauer`}</NewsReadTime>}
                </NewsInfo>
                {article["__typename"] !== "NewsEmergency" && (
                    <NewsImageWrapper>
                        <NewsImageFrame>
                            <NewsImage
                                src={
                                    article.content?.image?.imageUrl ||
                                    article.content?.newsTemplate?.mainImage?.url ||
                                    fallbackImage
                                }
                                alt={content.title ?? undefined}
                            />
                        </NewsImageFrame>
                        {article.content?.image?.description && (
                            <NewsCaption>{article.content?.image.description}</NewsCaption>
                        )}
                    </NewsImageWrapper>
                )}
                {content.text && (
                    <TextWrapper>
                        <BlockText text={content.text} />
                    </TextWrapper>
                )}
            </Content>
            {article["__typename"] !== "NewsEmergency" && article.content && (
                <React.Fragment>
                    {article.content.video && (
                        <Content>
                            <ArticleVideo
                                src={article.content.video + "#t=0.1"}
                                controls
                                preload="metadata"
                            />
                        </Content>
                    )}
                    {article.content.imageSlider && (
                        <ImageSliderWrapper>
                            <ImageSlider {...article.content.imageSlider} />
                        </ImageSliderWrapper>
                    )}
                    {article.content.rating && (
                        <Content $breakMobile>
                            <NewsRatingWrapper>
                                <NewsRating
                                    session={session}
                                    allocation={ratingData ? ratingData.allocation : null}
                                    onSelect={reactionId => {
                                        if (onRating) {
                                            onRating(reactionId);
                                        }
                                    }}
                                />
                            </NewsRatingWrapper>
                        </Content>
                    )}
                </React.Fragment>
            )}
            <Content>
                <NewsFooter>
                    {subTagsCollection && subTagsCollection.items.length > 0 && (
                        <TagsList>
                            {subTagsCollection.items.map(tag => (
                                <NewsTag key={tag.sys.id}>{tag.title}</NewsTag>
                            ))}
                        </TagsList>
                    )}
                    <NewsPrint type="button" onClick={() => window.print()}>
                        <IconPrint />
                    </NewsPrint>
                </NewsFooter>
            </Content>
        </NewsWrapper>
    );
};
