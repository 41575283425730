export function groupBy<T>(array: T[], predicate: (v: T) => string | null | undefined) {
    return array.reduce((acc, value) => {
        const p = predicate(value);
        if (p != null && p != undefined) {
            if (acc[p]) {
                acc[p].push(value);
            } else {
                acc[p] = [value];
            }
        }
        return acc;
    }, {} as { [key: string]: T[] });
}

export const calcReadTime = (wordCount: number) => Math.ceil(wordCount / 183);

export function isString(x: any) {
    return Object.prototype.toString.call(x) === "[object String]";
}

export function isObject(obj: any) {
    return Object.prototype.toString.call(obj) === "[object Object]";
}
