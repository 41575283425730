import { handleQuery } from "../../../apollo";
import { IMPRINT_COLLECTION, ImprintCollection } from "./query";

export const getImprintCollection = async (
    preview: boolean,
    locale?: string
): Promise<Common.Imprint | null> => {
    try {
        const { imprintCollection } = await handleQuery<ImprintCollection>({
            query: IMPRINT_COLLECTION,
            variables: { preview, locale },
            context: {
                preview,
            },
        });

        return imprintCollection.items[0] || null;
    } catch (error) {
        return null;
    }
};
