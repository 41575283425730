import styled, { css } from "styled-components";

export const NodeWrapper = styled.button`
    display: flex;
    align-items: center;
    position: relative;
    white-space: nowrap;
    padding: 2.4rem 5rem;
    min-width: 8rem;

    &::before,
    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        width: calc(100% - 1rem);
        height: 100%;
        background: inherit;
    }

    &::before {
        left: 0;
        transform-origin: 100% 0;
        transform: skew(-15deg);
    }

    &::after {
        right: 0;
        transform-origin: 0 100%;
        transform: skew(-15deg);
    }
`;

export const organization = css`
    .nodes-theme {
        .link {
            fill: none;
            stroke: ${p => p.theme.gray500};
            stroke-width: 1.5px;
        }

        .avatar {
            color: ${p => p.theme.primary};
            background-color: ${p => p.theme.orange400};
            border-color: ${p => p.theme.gray50};
        }

        .node-active {
            .node-management-frame,
            .node-business-area,
            .node-functional-area {
                &::before,
                &::after {
                    box-shadow: 0 0 0 0.4rem ${p => p.theme.primary};
                }
            }

            .node-department,
            .node-site {
                &::before,
                &::after {
                    box-shadow: 0 0 0 0.4rem ${p => p.theme.gray900};
                }
            }

            .node-team {
                box-shadow: 0 0 0 0.4rem ${p => p.theme.primary};
            }
        }

        .node-team {
            background-color: ${p => p.theme.gray50};
            color: ${p => p.theme.gray900};

            .node-team-content {
                border-color: ${p => p.theme.gray200};
            }

            .node-team-tag {
                color: ${p => p.theme.gray600};
            }

            .node-team-icon {
                color: ${p => p.theme.gray600};
            }
        }

        .node-business-area {
            .node-business-area-frame {
                color: ${p => p.theme.white};
                background: ${p => p.theme.secondaryGradient};
            }

            .node-business-area-accent {
                &::after {
                    background: ${p => p.theme.primaryGradient};
                }
            }
        }

        .node-site {
            color: ${p => p.theme.white};
            background: ${p => p.theme.primaryGradient};
        }

        .node-functional-area {
            .node-functional-area-frame {
                color: ${p => p.theme.white};
                background: ${p => p.theme.secondaryGradient};
            }
        }

        .node-department {
            .node-department-frame {
                color: ${p => p.theme.white};
                background-color: ${p => p.theme.primary};
            }
        }

        .node-management {
            .node-management-frame {
                color: ${p => p.theme.white};
                background: ${p => p.theme.secondaryGradient};
            }

            .node-management-tag {
                color: ${p => p.theme.gray};
            }

            .node-management-avatar {
                background: ${p => p.theme.blue};
                border-color: ${p => p.theme.blue};
            }
        }

        .node-employee {
            color: ${p => p.theme.gray50};

            .node-employee-frame {
                color: ${p => p.theme.gray900};
                background: ${p => p.theme.gray50};
            }

            .node-employee-tag {
                color: ${p => p.theme.gray};
                text-align: center;
            }

            .node-employee-avatar {
                background: ${p => p.theme.gray50};
                border-color: ${p => p.theme.gray50};
            }
        }
    }

    .is-safari {
        .nodes-theme {
            .node-site,
            .node-business-area,
            .node-department,
            .node-functional-area,
            .node-management-frame {
                position: unset;

                &::before,
                &::after {
                    content: unset;
                }
            }

            .node-team {
                .node-team-item {
                    z-index: unset;
                    transform: unset;
                }
            }

            .node-management {
                .node-management-avatar {
                    display: none;
                }
            }

            .node-business-area {
                .node-business-area-accent {
                    display: none;
                }
            }
        }
    }
`;
