export const buildHiearchy = (
    department: Organization.Department | undefined | null,
    departments: Organization.Department[]
): Organization.Node[] => {
    if (department && department.level !== "MANAGEMENT") {
        return buildDetailHiearchy(department, departments);
    }

    return buildOverviewHiearchy(departments);
};

export const buildDetailHiearchy = (
    department: Organization.Department,
    departments: Organization.Department[]
) => {
    if (!department.parentId) {
        return buildOverviewHiearchy(departments);
    }

    // TODO: Handle unique cases for each org level
    const [parent] = departments.filter(parent => parent.id === department.parentId);

    if (!parent) {
        return buildOverviewHiearchy(departments);
    }

    let children = departments.filter(other => other.parentId === department.id);

    const managers =
        department?.positions
            ?.filter(position => position.managementRole)
            .flatMap(position => position.employees) ?? [];

    const [manager] = managers;

    const employees =
        department?.positions
            ?.filter(position => !position.managementRole)
            .flatMap(position => position.employees)
            .filter(employee => !!employee)
            .reduce(
                (acc, next) =>
                    next
                        ? acc.findIndex(a => a.id === next.id) === -1
                            ? [...acc, next]
                            : acc
                        : acc,
                [] as Organization.Employee[]
            ) ?? [];

    const [parentManager] =
        parent?.positions
            ?.filter(position => position.managementRole)
            .flatMap(position => position.employees) ?? [];

    const parentNode = { ...parent, parentId: null };

    // TODO: Find better solution to management employee problem
    const parentManagerNode = parentManager &&
        parent.level !== "MANAGEMENT" && {
            ...parentManager,
            level: "HEAD_OF",
            id: parentManager.id,
            parentId: parentNode.id,
        };

    if (parentManagerNode) {
        department = { ...department, parentId: parentManagerNode.id };
    } else {
        department = { ...department, parentId: parentNode.id };
    }

    const managerNode = manager && {
        ...manager,
        level: "HEAD_OF",
        id: manager.id,
        parentId: department.id,
    };

    const managersNode = managers && {
        level: "EMPLOYEES",
        employees: managers,
        id: `${department.id}-managers`,
        parentId: department.id,
    };

    const hasMultipleManagers = managers.length > 1;

    const employeesNode = employees &&
        employees.length > 0 && {
            level: "EMPLOYEES",
            employees,
            id: `${department.id}-employees`,
            parentId: department.id,
        };

    if (managerNode) {
        children = children.map(child => ({ ...child, parentId: managerNode.id }));
    }

    if (employeesNode) {
        children = children.map(child => ({ ...child, parentId: `${department.id}-employees` }));

        if (managersNode) {
            if (hasMultipleManagers) {
                employeesNode.parentId = managersNode.id;
            } else if (managerNode) {
                employeesNode.parentId = managerNode.id;
            }
        }
    }

    return [
        parentNode,
        parentManagerNode,
        department,
        hasMultipleManagers ? managersNode : managerNode,
        employeesNode,
        ...children,
    ].filter(node => !!node) as Organization.Node[];
};

export const buildOverviewHiearchy = (departments: Organization.Department[]) => {
    const [faller] = departments.filter(department => !department.parentId);
    const management = departments.filter(department => department.parentId === faller.id);
    const children = management.flatMap(management =>
        departments.filter(
            department => department.parentId && department.parentId === management.id
        )
    );

    return [faller, ...management, ...children];
};

export const getEmployeeQuery = (id?: string | null) => {
    return { query: id ? { employee: id } : {} };
};

export const getDepartmentQuery = (id?: string | null) => {
    return { query: id ? { department: id } : {} };
};
